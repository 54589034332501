// @flow

import React from 'react';
import {Block} from 'jsxstyle';
import Fade from 'react-reveal/Fade';

import type {Node, Ref} from 'react';

type Props = {
  background: string,
  padding?: string,
  margin?: string,
  height?: string,
  width?: string,
  children?: Node,
  sectionRef?: Ref<any>,
};

export default ({
  children,
  background,
  padding,
  margin,
  height,
  width,
  sectionRef,
}: Props) => (
  <Block
    component="section"
    padding={padding ? padding : '40px 0'}
    margin={margin ? margin : '0'}
    background={background}
    height={height ? height : 'auto'}
    width={width ? width : '100%'}
    props={{
      ref: sectionRef,
    }}
  >
    {React.Children.map(children, child => (
      <Fade opposite duration={1500} ssrFadeout>
        {child}
      </Fade>
    ))}
  </Block>
);
